function WebsiteProd() {
  return (
    <div className="WebsiteDev flex flex-col m-5">
      <div className="flex flex-wrap">
        <div className="m-3">
          <a href="https://e-denden.com" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-indigo-500 text-slate-200 rounded-xl shadow-2xl">Prod 유저</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://cs.e-denden.com" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-indigo-500 text-slate-200 rounded-xl shadow-2xl">Prod CS</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://admin.e-denden.com" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-indigo-500 text-slate-200 rounded-xl shadow-2xl">Prod Admin</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://policy.e-denden.com" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-indigo-500 text-slate-200 rounded-xl shadow-2xl">Prod Policy</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://partnership.e-denden.com/" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-indigo-500 text-slate-200 rounded-xl shadow-2xl">Next Partnership</p>
          </a>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="m-3">
          <a href="https://api.e-denden.com/user" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-indigo-500 text-slate-200 rounded-xl shadow-2xl">Prod 유저 API</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://api.e-denden.com/cs" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-indigo-500 text-slate-200 rounded-xl shadow-2xl">Prod CS API</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://api.e-denden.com/admin" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-indigo-500 text-slate-200 rounded-xl shadow-2xl">Prod Admin API</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://api.e-denden.com/dispatch" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-indigo-500 text-slate-200 rounded-xl shadow-2xl">Prod Dispatch API</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://capi.e-denden.com/connection" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-indigo-500 text-slate-200 rounded-xl shadow-2xl">Prod Connection API</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default WebsiteProd;
