import React, { useEffect, useState } from "react";
import './CertifyModal.scss';

function CertifyModal() {
  const [showModal, setShowModal] = useState(true);

  // Info 일때 인증값 검증 함수
  const CanCertify = () => {
    var today = new Date();

    var day = ("0" + today.getDate()).slice(-2);
    var hours = ("0" + today.getHours()).slice(-2);
    // var minutes = ('0' + today.getMinutes()).slice(-2);
    // var seconds = ('0' + today.getSeconds()).slice(-2);

    var certifyString = day + hours;
    const certifyValue = document.querySelector("#certifyValue").value;

    if (certifyString === certifyValue) {
      console.log("입력된 인증값 : " + certifyValue);
      console.log("모달 닫아요!!!!!!!!!!!!!!!!!!!!!!!!");
      setShowModal(false);
    } else {
      console.log("인증 값이 틀렸어요!!!!!!!!!!!!!!!");
    }
  };

  var todayString = new Date();
  var monthString = ("0" + todayString.getMonth()).slice(-2);
  var dayString = ("0" + todayString.getDate()).slice(-2);
  var hoursString = ("0" + todayString.getHours()).slice(-2);
  var minutesString = ('0' + todayString.getMinutes()).slice(-2);
  var secondsString = ('0' + todayString.getSeconds()).slice(-2);

  return (
    <>
      {showModal && (
        <div className="certify-modal-container">
          <form className="modal-body">
            
            <h2>
              Eden Corp
              <br />
              <b>Groupware</b>
            </h2>
            
            <p>
              이든파이낸셜의 그룹웨어 링크 페이지 입니다.
              <br />
              단순 링크들만 모아 놓은 곳으로 다른 기능을 제공하지 않습니다.
              
              <br />
              <br />
              접속을 위한 패스워드가 필요합니다.
              <br />
            </p>
            <div className="modal-input-box">
              <input
                autoFocus
                type="text"
                id="certifyValue"
                inputMode="numeric"
              ></input>
            </div>
            <button onClick={() => CanCertify()} className="modal-button">
              접속
            </button>
            {/* <img src={certify} alt="certify" className="certifyImg" /> */}
          <br />
              서버시간 : {monthString}/{dayString} {hoursString}:{minutesString}.{secondsString}
          </form>
        </div>
      )}
    </>
  );
}

export default CertifyModal;
