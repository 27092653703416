function WebsiteDev() {
  return (
    <div className="WebsiteDev flex flex-col m-5">
      <div className="flex flex-wrap">
        <div className="m-3">
          <a href="https://e-denden.live" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-fuchsia-300 text-neutral-500 rounded-xl shadow-2xl">Live 유저</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://cs.e-denden.live" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-fuchsia-300 text-neutral-500 rounded-xl shadow-2xl">Live CS</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://admin.e-denden.live" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-fuchsia-300 text-neutral-500 rounded-xl shadow-2xl">Live Admin</p>
          </a>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="m-3">
          <a href="https://api.e-denden.live/user" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-fuchsia-300 text-neutral-500 rounded-xl shadow-2xl">Live 유저 API</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://api.e-denden.live/cs" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-fuchsia-300 text-neutral-500 rounded-xl shadow-2xl">Live CS API</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://api.e-denden.live/admin" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-fuchsia-300 text-neutral-500 rounded-xl shadow-2xl">Live Admin API</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://api.e-denden.live/dispatch" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-fuchsia-300 text-neutral-500 rounded-xl shadow-2xl">Live Dispatch API</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default WebsiteDev;
