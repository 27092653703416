function Groupware() {
  return (
    <div className="Groupware flex flex-col m-5">
      <div className="m-1">
        <a href="https://eden.ncpworkplace.com" target="_blank" rel="noreferrer" className="App-link">
          <p className="text-2xl font-bold p-5 bg-green-500 text-neutral-600 rounded-xl shadow-2xl">NAVER WORKPLACE 바로가기</p>
        </a>
      </div>
      <div className="m-1">
        <a href="http://board.edencorp.kr" target="_blank" rel="noreferrer" className="App-link">
          <p className="text-2xl font-bold p-5 bg-green-500 text-neutral-600 rounded-xl shadow-2xl">이든 파이낸셜 그룹웨어 바로가기</p>
        </a>
      </div>
      <div className="m-1">
        <a href="http://mail.edencorp.kr" target="_blank" rel="noreferrer" className="App-link">
          <p className="text-2xl font-bold p-5 bg-green-500 text-neutral-600 rounded-xl shadow-2xl">이메일 바로가기</p>
        </a>
      </div>
      <div className="m-1">
        <a href="http://drive.edencorp.kr" target="_blank" rel="noreferrer" className="App-link">
          <p className="text-2xl font-bold p-5 bg-green-500 text-neutral-600 rounded-xl shadow-2xl">드라이브 바로가기</p>
        </a>
      </div>
      <div className="m-1">
        <a href="https://naver.worksmobile.com/download/" rel="noreferrer" target="_blank" className="App-link">
        <p className="text-2xl font-bold p-5 bg-emerald-600 text-neutral-600 rounded-xl shadow-2xl">그룹웨어 다운로드</p>
        </a>
      </div>
    </div>
  );
}

export default Groupware;
