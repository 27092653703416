function WebsiteDev() {
  return (
    <div className="WebsiteDev flex flex-col m-5">
      <div className="flex flex-wrap">
        <div className="m-3">
          <a href="https://e-denden.info" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-sky-500 text-orange-300 rounded-xl shadow-2xl">Info 유저</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://cs.e-denden.info" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-sky-500 text-orange-300 rounded-xl shadow-2xl">Info CS</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://admin.e-denden.info" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-sky-500 text-orange-300 rounded-xl shadow-2xl">Info Admin</p>
          </a>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="m-3">
          <a href="https://api.e-denden.info/user" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-sky-500 text-orange-300 rounded-xl shadow-2xl">Info 유저 API</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://api.e-denden.info/cs" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-sky-500 text-orange-300 rounded-xl shadow-2xl">Info CS API</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://api.e-denden.info/admin" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-sky-500 text-orange-300 rounded-xl shadow-2xl">Info Admin API</p>
          </a>
        </div>
        <div className="m-3">
          <a href="https://api.e-denden.info/dispatch" target="_blank" rel="noreferrer" className="App-link">
            <p className="text-2xl font-bold p-3 bg-sky-500 text-orange-300 rounded-xl shadow-2xl">Info Dispatch API</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default WebsiteDev;
