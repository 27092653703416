import './App.scss';
import CertifyModal from './Page/CertifyModal.jsx'
import Groupware from './Page/Groupware.jsx'
import WebsiteDev from './Page/WebsiteDev.jsx'
import WebsiteStg from './Page/WebsiteStg.jsx'
import WebsiteProd from './Page/WebsiteProd.jsx'

function App() {
  return (
    <div className="App flex flex-col">
      <CertifyModal />
      <Groupware />
      <WebsiteDev />
      <WebsiteStg />
      <WebsiteProd />
    </div>
  );
}

export default App;
